import React, { useEffect, useRef, useState } from 'react'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import moment from 'moment/moment';
import Index from '../../../../Assets/Images/Index';
import Pagination from 'react-bootstrap/Pagination';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import _ from "underscore";
import { addYears } from "date-fns";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import DatePicker from "react-datepicker";
import { energyfunction } from '../../../../Config/energyFunction';
import "react-datepicker/dist/react-datepicker.css";
import CurrencyFormat from 'react-currency-format';
import Sessions from './Sessions';
import ChargeStation from './ChargeStation';

const options = [
    { name: "Current month", value: "current_month", },
    { name: "Past month", value: "last_month", },
    { name: "Past 12 months", value: "last_12_months", },
]
const options2 = [
    { name: "Paid", value: "Paid", },
    { name: "Unpaid", value: "Unpaid", },
    { name: "Cancelled", value: "Cancelled", },
    { name: "Refunded", value: "Refunded", }
]
const options3 = [
    { name: "Card", value: "Card", },
    { name: "Wallet", value: "Wallet", },
]
const ReportingOptions = [
    { name: "Session", value: "session", },
    { name: "Charging Station", value: "charging_station", },

]

export default function Reporting() {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [loading, setloading] = useState(true)
    const [sessionList, setsessionList] = useState([])
    const [selectedReport, setselectedReport] = useState("session")
    const [chargingStationList, setchargingStationList] = useState([])
    const [chargingStations, setchargingStations] = useState([])
    const [checkedCharger, setcheckedCharger] = useState([])
    const boxRef = useRef(null);
    const [showChargStstList, setshowChargStstList] = useState(false)
    const [toutalcount, settoutalcount] = useState({
        totalSessionsRevenue: 0.00,
        totalConsumption: 1,
        TotalUsers: 1,
        TotalchargingStation: 1,
        duration: 1
    })
    const [count, setCount] = useState(1)
    const toggleChargerList = () => {
        setshowChargStstList(!showChargStstList)
    }
    const [total, setTotal] = useState(0)
    let limit = 20
    const [filterDate, setfilterDate] = useState({
        from: searchParams.get("from") ? new Date(searchParams.get("from")) : "",
        to: searchParams.get("to") ? new Date(searchParams.get("to")) : ""
    })

    const [params, setParams] = useState({
        filter: searchParams.get("filter") || "",
        search: searchParams.get("search") || "",
        payment: searchParams.get("payment") || "",
        payment_method: searchParams.get("payment_method") || "",
    })
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }

    const onReportTypeChange = (e) => {
        deleteparam("payment")
        setloading(true)
        clearFilter()
        setselectedReport(e.target.value)
        setTimeout(() => {
            if (e.target.value == "session") {
                SessionData(location.search)
            } else {
                chargeStationget("")
            }
        }, 100);



    }


    const onSelectChange = (e) => {
        setfilterDate({ from: "", to: "" });
        setParams({ ...params, from: "", to: "", [e.target.name]: e.target.value });
        deleteparam("from")
        deleteparam("to")
        setparam([e.target.name], e.target.value)

    }
    const onDateChange = (date, name) => {
        if (name === "from") {
            setfilterDate({ ...filterDate, [name]: date, to: "" })
        } else {
            setfilterDate({ ...filterDate, [name]: date })
        }
    }

    const [dateError, setdateError] = useState(false)
    const filterDateChange = () => {

        if (filterDate?.from == "" || filterDate?.to == "") {
            setdateError(true)
        } else {
            setdateError(false)
            deleteparam("filter")
            setParams({ ...params, "from": moment(filterDate?.from).format("YYYY-MM-DD"), "to": moment(filterDate?.to).format("YYYY-MM-DD"), filter: "" })
            setparam("from", moment(filterDate?.from).format("YYYY-MM-DD"))
            setparam("to", moment(filterDate?.to).format("YYYY-MM-DD"))
        }
    }
    const clearDate = () => {
        setfilterDate({ from: "", to: "" });
        setParams({ ...params, from: "", to: "" });
        deleteparam("from")
        deleteparam("to")
    };
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (params.search != "") {
                setparam('search', params.search)
            }
            // SessionData(`?search=${params.search}`)
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [params.search])

    const [selectAll, setselectAll] = useState(false)
    const handleCheck = (e) => {
        if (e.target.checked) {
            let arr = []
            if (e.target.value == "selectAll") {
                chargingStations.map((opt) => {
                    arr.push(opt?._id._id)
                })
                setselectAll(true)
            } else {
                setselectAll(false)
                arr = [...checkedCharger]
                arr.push(e.target.value)
            }
            setcheckedCharger(arr)
        } else {
            setselectAll(false)
            let arr = []
            if (e.target.value !== "selectAll") {
                arr = checkedCharger.filter((x) => x !== e.target.value)
            }
            setcheckedCharger(arr)
        }
    }



    const clearSort = () => {
        setParams({ ...params, payment: "", filter: "" })
        deleteparam("filter")
        deleteparam("payment")
    }
    const clearFilter = () => {
        setParams({ ...params, filter: "", payment: "", payment_method: "" })
        // setfilterDate({ from: "", to: "" })
        deleteparam("filter")
        // deleteparam("from")
        // deleteparam("to")
        deleteparam("payment")
        deleteparam("payment_method")
    }

    const handleExport = () => {
        let hitpoint;

        if (selectedReport === "session") {
            hitpoint = endPoints.SESSION
        } else {
            hitpoint = endPoints.CHARGE_STATIONS
        }
        let body = {
            ...params
        }
        if (checkedCharger.length > 0) {
            body = {
                ...body,
                chargeStationList: checkedCharger
            }
        }
        axios({
            method: selectedReport === "session" ? 'get' : "post",
            url: `${Api.appBaseURL}${hitpoint}/csv${selectedReport === "session" ? location.search : ""}`,
            data: clean(body),
            responseType: 'blob',
            headers: {
                "token": Api.isLogIn().token,
            }
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]), {
                type: 'text/csv'
            });

            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = `${selectedReport === "session" ? "Session" : "Charge_station"}${filterDate?.from && filterDate?.to ? `_${moment(filterDate?.from).format("YYYY-MM-DD")}_to_${moment(filterDate?.to).format("YYYY-MM-DD")}` : `_${moment(new Date()).format("YYYY-MM-DD")}`}.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }).catch((err) => {
            Api.Alert(err, "error")
        });
    }


    const chargeStationList = (body) => {
        Api.PostApi(endPoints.CHARGE_STATIONS, body).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                setchargingStations(res.data.data?.chargeStation.length > 0 ? res.data.data?.chargeStation[0].result : [])
            }
        })
    }

    const chargeStationget = (body) => {
        Api.PostApi(endPoints.CHARGE_STATIONS, body).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                setchargingStationList(res.data.data?.chargeStation.length > 0 ? res.data.data?.chargeStation[0].result : [])
                setloading(false)
                // console.log("res.data.data", res.data.data)
                console.log("only 2 count")
                settoutalcount({
                    totalRevenue: res.data.data?.totalRevenue,
                    TotalchargingStation: res.data.data?.TotalchargingStation,
                })
                if (res.data.data?.chargeStation.length > 0) {
                    setTotal(res.data.data?.chargeStation[0]?.totalCount?.count)
                    let newCount = Math.ceil(parseInt(res.data.data?.chargeStation[0]?.totalCount?.count) / limit)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount(items)
                    } else {
                        setCount(1)
                    }
                }

            }
        })
    }
    // console.log("chargingStationList: ", chargingStationList)

    const SessionData = (param) => {
        Api.GetApi(`${endPoints.SESSION}${param}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {

                let arr = []
                res.data.data?.sessions.length > 0 && res.data.data?.sessions[0].result.length > 0 && res.data.data?.sessions[0].result.map((opt) => {
                    // var a = moment(opt?._id?.duration?.startTime);
                    // var b = moment(opt?._id?.duration?.endTime);
                    // var duration = moment.duration(b.diff(a));
                    // duration.hours()
                    // duration.minutes()
                    // duration.seconds()
                    // let duration1 = `${duration.hours() > 0 ? `${duration.hours()}hr` : ""} 
                    // ${duration.minutes() > 0 ? `${duration.minutes()}min ` : ""} ${duration.seconds() > 0 ? `${duration.seconds()}s` : ""}`

                    // console.log("endTime", opt?._id?.duration?.endTime)
                    // console.log("startTime", opt?._id?.duration?.startTime)

                    var milliseconds = moment(opt?._id?.duration?.endTime, 'YYYY-MM-DD hh:mm:ss A').diff(moment(opt?._id?.duration?.startTime, 'YYYY-MM-DD hh:mm:ss A'));


                    let seconds = parseInt(Math.round(((milliseconds / 1000) % 60)));
                    let minutes = parseInt((((milliseconds / (1000 * 60)) % 60)));
                    let hours = parseInt((((milliseconds / (1000 * 60 * 60)) % 24)));

                    // console.log("seconds", seconds)
                    // console.log("minutes", minutes)
                    // console.log("hours", hours)

                    let duration3 = ` ${hours > 0 ? `${hours}h` : ""} ${minutes > 0 ? `${minutes}m` : ""} ${seconds > 0 ? `${seconds}s` : ""}`

                    // console.log("duration3",duration3)
                    arr.push({ ...opt?._id, duration2: duration3 })
                })

                setsessionList(arr)
                console.log("5 count")
                settoutalcount({
                    totalSessionsRevenue: res.data.data?.totalSessionsRevenue,
                    totalConsumption: res.data.data?.totalConsumption,
                    TotalUsers: res.data.data?.TotalUsers,
                    TotalchargingStation: res.data.data?.TotalchargingStation,
                    duration: res.data.data?.duration
                })

                if (res.data.data?.sessions.length > 0) {
                    setTotal(res.data.data?.sessions[0]?.totalCount?.count)
                    let newCount = Math.ceil(parseInt(res.data.data?.sessions[0]?.totalCount?.count) / limit)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount(items)
                    } else {
                        setCount(1)
                    }
                }
                setloading(false)
            }
        })
    }

    function clean(obj) {
        for (var propName in obj) {
            if (obj[propName] === "" || obj[propName] === undefined || (typeof obj[propName] == "object" && obj[propName].length == 0) || propName === "payment") {
                delete obj[propName];
            }
        }
        return obj
    }

    useEffect(() => {
        if (!searchParams.get("from") && !searchParams.get("to")) {
            clearDate()
        }
        if (!searchParams.get("payment") && !searchParams.get("filter")) {

            clearFilter()
        }
        if (selectedReport == "session") {
            if (location.search) {
                SessionData(location.search)
            } else {
                setselectedReport("session")
                SessionData("")
            }
        } else {
            if (location.search) {
                let body = {
                    ...params
                }
                clean(body)
                if (checkedCharger.length > 0) {
                    body = {
                        ...body,
                        chargeStationList: checkedCharger
                    }
                }
                chargeStationget(body)
            } else {
                chargeStationget("")

            }
        }
    }, [location.search, window.location])


    useEffect(() => {
        let body = {
            ...params,
            chargeStationList: checkedCharger
        }
        if (selectedReport != "session") {

            chargeStationget(clean(body))
        }
    }, [checkedCharger])

    useEffect(() => {
        chargeStationList("")
    }, [])



    const handleClickOutside = (event) => {
        if (boxRef.current && !boxRef.current.contains(event.target)) {
            // alert("ads")
            setshowChargStstList(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const clearSelectedChargingStation = () => {
        setshowChargStstList(false)
        setcheckedCharger([])
    }

    console.log("toutalcount", toutalcount)
    console.log("checkedCharger", checkedCharger)

    // console.log("location.search", location.search)
    return (
        <main id="main" className="main">
            <div className="pagetitle pagetitleFilters d-flex flex-wrap  justify-content-between align-items-start">
                <div className='d-flex flex-grow-1 flex-wrap'>
                    <h1 className="me-3">Reports</h1>
                    <div className='pagetitleFilters__left d-flex flex-wrap'>
                        <div className='sortingSelect_outer me-4'>
                            <select className='sortingSelect' name='filter' value={selectedReport}
                                onChange={onReportTypeChange}>
                                {ReportingOptions.map((data, index) => {
                                    return (
                                        <option key={index} value={data.value}>{data.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        {selectedReport !== "session" ?
                            <>
                                <div ref={boxRef} className={showChargStstList ? 'show dropdown chargerDropDown' : 'dropdown chargerDropDown'}>

                                    <div className='dropdown-toggle sortingSelect' onClick={toggleChargerList}> Select Charging Station</div>
                                    <div className={showChargStstList ? 'dropdown-menu show' : 'dropdown-menu'}>
                                        <Form.Check
                                            type={"checkbox"}
                                            id="selectAll"
                                            label="Select all"
                                            value="selectAll"
                                            onChange={handleCheck}
                                            checked={selectAll}
                                        />
                                        <hr className='my-2' />
                                        <div className='checkbox_list'>
                                            {chargingStations && chargingStations.map((opt, i) => {
                                                return (
                                                    <Form.Check
                                                        key={opt?._id._id}
                                                        type={"checkbox"}
                                                        id={opt?._id._id}
                                                        label={opt?._id.chargestation_name || ""}
                                                        value={opt?._id._id}
                                                        onChange={handleCheck}
                                                        checked={checkedCharger.filter((x) => x == opt?._id._id).length > 0}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </div>


                                </div>
                                {checkedCharger.length > 0 ? <button type="button" className="textClear ms-2" onClick={clearSelectedChargingStation}  >Clear</button> : null}
                            </>
                            : null
                        }
                    </div>
                </div>
                {selectedReport === "session" ?
                    sessionList && sessionList?.length > 0 ?
                        <Button variant="primary" size='sm' className='me-2 align-items-center' onClick={handleExport} > Export CSV   </Button>
                        : null
                    :
                    chargingStationList && chargingStationList?.length > 0 ?
                        <Button variant="primary" size='sm' className='me-2 align-items-center' onClick={handleExport} >  Export CSV   </Button>
                        : null

                }
            </div>
            {loading ?
                <div className="loader-line"></div>
                :
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex flex-wrap mb-0 mb-md-4 justify-content-between align-items-center userdetailTopRow">
                            <div className="  mb-3 mb-md-0  userdetailTopRow__left">
                                {/*  <div className="search-form d-flex align-items-center" >
                                <input type="text" className='form-control' name="search" placeholder="Search" value={params.search} title="Enter search keyword" onChange={searchUser} />
                                <span className="searchBarIcon" title="Search"><Icon.Search /></span>
                            </div> */}
                                <div className='dateFilterBox flex-wrap  d-flex align-items-start  me-md-5'>
                                    <div className="dateFilterBox__col me-2">
                                        <DatePicker
                                            className="reportingSelectBox"
                                            selected={filterDate.from}
                                            onChange={(date) => onDateChange(date, "from")}
                                            selectsStart
                                            startDate={filterDate.from}
                                            endDate={filterDate.to}
                                            placeholderText="Start Date"
                                            maxDate={new Date()}
                                        />
                                        <div className='error small' >
                                            {dateError && filterDate?.from == "" ? "Please choose start date " : null}
                                        </div>
                                    </div>
                                    <div className="dateFilterBox__col ">
                                        <DatePicker
                                            className="reportingSelectBox"
                                            selected={filterDate.to}
                                            onChange={(date) => onDateChange(date, "to")}
                                            selectsEnd
                                            startDate={filterDate.from}
                                            endDate={filterDate.to}
                                            minDate={filterDate.from}
                                            placeholderText="End Date"
                                            maxDate={addYears(new Date(filterDate?.from), 1) < new Date() ? addYears(new Date(filterDate?.from), 1) : new Date()}
                                        />
                                        <div className='error small' >
                                            {dateError && filterDate?.to == "" ? "Please choose end date " : null}
                                        </div>
                                    </div>
                                    <div className="dateFilterBox__btncol">
                                        <button className='btn btn-primary btn-sm ms-2 ' onClick={filterDateChange}>Filter</button>
                                        {filterDate.to !== "" || filterDate.from !== ""
                                            ? <button type="button" className="textClear" onClick={clearDate}>Clear Date</button> : null}
                                    </div>
                                </div>
                            </div>
                            <div className=" d-flex align-items-center userdetailTopRow__right">
                                {/* <label className="m-0 me-2 ">Filter by</label> */}
                                {selectedReport == "session" ?
                                    <>
                                        {/* <div className='sortingSelect_outer me-3 '>
                                            <select className='sortingSelect' name='payment_method' value={params.payment_method} onChange={onSelectChange}>
                                                <option disabled defaultValue value="">Payment Method </option>
                                                {options3.map((data, index) => {
                                                    return (
                                                        <option key={index} value={data.value}>{data.name}</option>
                                                    )
                                                })

                                                }
                                            </select>
                                        </div> */}
                                        <div className='sortingSelect_outer me-1 '>
                                            <select className='sortingSelect' name='payment' value={params.payment} onChange={onSelectChange}>
                                                <option disabled defaultValue value="">Payment Status </option>
                                                {options2.map((data, index) => {
                                                    return (
                                                        <option key={index} value={data.value}>{data.name}</option>
                                                    )
                                                })

                                                }
                                            </select>
                                        </div>
                                    </> : null}
                                <div className='sortingSelect_outer ms-2'>
                                    <select className='sortingSelect' name='filter' value={params.filter} onChange={onSelectChange}>
                                        <option disabled defaultValue value="">Filter By</option>
                                        {options.map((data, index) => {
                                            return (
                                                <option key={index} value={data.value}>{data.name}</option>
                                            )
                                        })

                                        }
                                    </select>
                                </div>
                                {params.filter !== "" || params.payment !== "" || params.payment_method !== "" ?
                                    <button type="button" className="textClear ms-2" onClick={clearSort}>Clear Filter</button> : null}



                                {/* 
                            <label className="m-0 me-2 ">Sort by</label>
                            <select className='sortingSelect' name='sort_key' value={params.sort_key} onChange={onSelectChange}>
                                 <option disabled defaultValue value="">Sort By</option>
                                {options.map((data, index) => {
                                    return (
                                        <option key={index} value={data.value}>{data.name}</option>
                                    )
                                })}
                            </select>
                            <select className='sortingSelect' name='sort_value' value={params.sort_value} onChange={onSelectChange}>
                                {sortTypes.map((data, index) => {
                                    return (
                                        <option key={index} value={data.value}>{data.name}</option>
                                    )
                                })}
                            </select>
                            {params.sort_key !== "" ?
                                <button type="button" className="textClear" onClick={clearFilter}>Clear Filter</button> : null} */}

                            </div>
                        </div>

                        {selectedReport == "session" ?
                            <Sessions list={sessionList} summary={toutalcount} />
                            :
                            <ChargeStation list={chargingStationList} summary={toutalcount} />
                        }
                    </div>
                </div>
            }
        </main>
    )
}
