import React, { useState } from 'react'
import Index from '../../../Assets/Images/Index'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Api from "../../../Config/Apis"
import endPoints from "../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';


export default function ResetPassword() {
    const navigate = useNavigate()
    const [errorObj, setErrorObj] = useState({
        error: false,
        errorMessage: "",
    })
    const [userProfil, setUserProfil] = useState({
        password: "",
        confirm_password: "",

    })
    const [password, setpassword] = useState({
        password: false,
        confirm_password: false,
    })
    const toggleShowPassword = (name) => {
        setpassword({ ...password, [name]: !password[name] })
    }
    let { token } = useParams();

    const handlechange = (e) => {
        setUserProfil({ ...userProfil, [e.target.name]: e.target.value })
    }

    const handlePasswordSubmit = (event) => {
        event.preventDefault();
        var reg = new RegExp(
            "^(?=.*)(?=.*[a-z])(?=.*[!@#%&])(?=.*[0-9])(?=.*[A-Z]).{8,32}$"
        );
        var validPass = reg.test(userProfil.password);
        let formData = new FormData();
        if (userProfil.password == "") {
            setErrorObj({
                error: true,
                errorMessage: "Please enter Password",
            });
        } else if (!validPass) {
            setErrorObj({
                error: true,
                errorMessage: "Create a secure password with at least 8 characters, including uppercase, lowercase, numbers, and symbols",
            });
        } else if (userProfil.confirm_password == "") {
            setErrorObj({
                error: true,
                errorMessage: "Please enter Confirm Password",
            });
        } else if (userProfil.password !== userProfil.confirm_password) {
            setErrorObj({
                error: true,
                errorMessage: "Password and confirm password should be same",

            });
        }
        else {

            setErrorObj({ error: false, errorMessage: "", });

            let userDEtail = {
                token: token,
                password: userProfil.password,
            }
            forgotPasswordfunct(userDEtail)
        }
    }
    // 


    const forgotPasswordfunct = (data) => {
        Api.PutApi(endPoints.USER_VERIFY, data).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                if (res.response.data.error === "ALREADY_VERIFY") {
                    setTimeout(() => {
                        navigate('/login')

                    }, 2000);
                }

            }
            else {
                Api.Alert("Password updated successfully.", "success")
                setTimeout(() => {
                    navigate('/login')

                }, 2000);
            }
        })
    }



    return (
        <div className="loginPage">
            <div className="container">
                <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                        <div className="loginPageContainer justify-content-center">
                            <div className=" ">

                                <div className="d-flex justify-content-center py-4">
                                    <div className="logo d-flex align-items-center w-auto">
                                        <img src={Index.logo} alt="" />
                                    </div>
                                </div>
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="pb-2">
                                            <h5 className="card-title text-center fs-4">Reset Password</h5>
                                        </div>
                                        <form className="row g-3 needs-validation"
                                            onSubmit={handlePasswordSubmit}
                                        >
                                            <div className="col-12">{errorObj.error ? <div className="invalid-feedback mb-2 d-block">{errorObj.errorMessage}</div> : null}</div>

                                            <div className="col-12">
                                                <label htmlFor="yourUsername" className="form-label">Create new password</label>
                                                <div className="input-group has-validation">
                                                    <div className='passwordField'>
                                                        <input
                                                            type={password.password ? "text" : "password"}
                                                            // value={value.name}
                                                            name="password"
                                                            onChange={handlechange}
                                                            className="form-control" id="yourUsername" required="" />
                                                        <button type="button" className='passwordField__btn' onClick={() => toggleShowPassword("password")}>
                                                            {password.password ? <Icon.EyeSlashFill /> : <Icon.EyeFill />}
                                                        </button>
                                                    </div>
                                                    {/* <div className="invalid-feedback">Please enter your username.</div> */}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <label htmlFor="yourUsername" className="form-label">Confirm password</label>
                                                <div className="input-group has-validation">
                                                    <div className='passwordField'>
                                                        <input
                                                            type={password.confirm_password ? "text" : "password"}
                                                            // value={value.name}
                                                            name="confirm_password"
                                                            onChange={handlechange}
                                                            className="form-control" id="yourUsername" required="" />
                                                        <button type="button" className='passwordField__btn' onClick={() => toggleShowPassword("confirm_password")}>
                                                            {password.confirm_password ? <Icon.EyeSlashFill /> : <Icon.EyeFill />}
                                                        </button>
                                                    </div>
                                                    {/* <div className="invalid-feedback">Please enter your username.</div> */}
                                                </div>
                                            </div>



                                            <div className="col-12">
                                                <button
                                                    className="btn btn-primary w-100"
                                                    //  className={`btn btn-primary w-100 ${value.email === "" || value.password === "" ? "disabled" : null}`} 
                                                    onClick={handlePasswordSubmit}
                                                    type="submit">Submit Password </button>
                                            </div>
                                            {/* <div className="col-12">
                                                <p className="mb-0">Already have an account? <Link to="/login">login</Link></p>
                                            </div> */}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>
            </div>
        </div>
    )
}
