import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from './PrivateAuth';
import Login from './Components/Public/Login/Login';
import Header from './Components/Private/Header/Header'; 
import Sidebar from './Components/Private/Sidebar/Sidebar';
import DashBoard from './Components/Private/Pages/DashBoard/DashBoard';
import NotFound from './Components/Public/NotFound/NotFound';
import FotgotPassword from './Components/Public/FotgotPassword/FotgotPassword';
import ChargeSession from './Components/Private/Pages/ChargeSession/ChargeSession';
import Profile from './Components/Private/Pages/Profile/Profile';
import Reporting from './Components/Private/Pages/Reporting';
import Sessions from './Components/Private/Pages/Reporting/Sessions';
import ChargeStation from './Components/Private/Pages/Reporting/ChargeStation';
import Verify from './Components/Public/Verify/Verify';
import ResetPassword from './Components/Public/ResetPassword/ResetPassword';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { isLogIn } from './Config/Apis'; 

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const dataval = useSelector((state) => state.dataValue)
  useEffect(() => { }, [dataval])

  return (
    <div className="App">
      <Router>
        {isLogIn().isToken &&
          <>
            <Header />
            <Sidebar />
          </>
        }


        <Routes>
          <Route path="*" element={<NotFound />} exact />
          <Route exact path='/' element={<PublicRoute />}>
            <Route path="/login" element={<Login />} exact />
            <Route path="/invite/:token" element={<Verify />} exact />
            <Route path="/forgot/:token" element={<ResetPassword />} exact />
            <Route path="/forgot-password" element={<FotgotPassword />} exact />
          
            <Route path="/" element={<Login />} exact />
          </Route>
          {/* </Routes> */}
          {/* <main id="main" className="main"> */}
          {/* <Routes> */}
          <Route path="*" element={<NotFound />} exact />
          <Route exact path='/' element={<PrivateRoute />}>
            <Route path="/dashboard" element={<DashBoard />} exact />
            <Route path="/charge-session" element={<ChargeSession />} exact />
            <Route path="/reporting" element={<Reporting />} exact />
            {/* <Route path="/reporting/charge-station" element={<ChargeStation />} exact /> */}
            <Route path="/profile" element={<Profile />} exact /> 
            
          </Route>
        </Routes>
        {/* </main> */}
      </Router>
    </div>
  );
}

export default App;
