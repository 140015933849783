import React, { useEffect, useState } from 'react'
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

export default function Sidebar() {
    const [nav, setNav] = useState([
        { name: "Dashboard", link: "/dashboard", icon: <Icon.Grid className="me-2" />, submenu: "" },
        { name: "Charge Sessions", link: "/charge-session?page=1", icon: <Icon.EvStation className="me-2" />, submenu: "" },
        {
            name: "Reporting",
            link: "/reporting",
            icon: <Icon.FileEarmarkText className="me-2" />,
            // submenu: [
            //     {
            //         name: "Sessions",
            //         link: "/reporting/sessions",
            //     },
            //     {
            //         name: "Charge station",
            //         link: "/reporting/charge-station",
            //     }
            // ]
        },
        // { name: "Reporting", link: "/reporting/charge-station", icon: <Icon.FileEarmarkText className="me-2" />, },
       
    ])

    const dataVal = useSelector((state) => state.dataValue)
    useEffect(() => { }, [dataVal])
    return (
        <aside id="sidebar" className="sidebar">

            <ul className="sidebar-nav" id="sidebar-nav">
                {nav.map((data, index) => {
                    return (
                        <li className="list_item" key={index}>
                            <NavLink className="nav-link " to={data.link}>
                                {data.icon}
                                {data.name}
                            </NavLink>
                            {data.submenu ?
                                <>
                                    <ul className='nav-content collapse show'>
                                        {data.submenu.map((cntnt, ind) => {
                                            return <li key={ind}><NavLink to={cntnt.link}>{cntnt.name}</NavLink></li>
                                        })}

                                    </ul>
                                </>
                                : null}
                        </li>
                    )
                })}
            </ul>

        </aside>
    )
}
