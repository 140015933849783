import React, { useEffect, useState } from 'react'

import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment/moment';
import Index from '../../../../Assets/Images/Index';
import Form from 'react-bootstrap/Form';
import _ from "underscore"
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import { energyfunction } from '../../../../Config/energyFunction';
import { currencyName } from '../../../../Config/Apis';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  BarElement,
  Title,);

export const chartData = (data) => {
  return {
    labels: data.label,
    datasets: [
      {
        label: '',
        data: data.data,
        backgroundColor: [
          '#F58E23',
          '#ddd',
        ],
        borderColor: [
          '#F58E23',
          '#a9a9a9',
        ],
        borderWidth: 1,
      },
    ],
  }
};

export const options = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: ''
    }
  }
}

export const barChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
      text: ''
    }
  }
}

export const barChartData = (data) => {
  return {
    labels: data.label,
    datasets: [{
      label: false,
      data: data.data,
      backgroundColor: ['#F58E23'],
      // borderColor: ['#F58E23'],
      borderWidth: 0
    }]
  }
};



const selessionOption = [
  { name: "7 days", value: "7days" },
  { name: "14 days", value: "14days" },
  { name: "30 days", value: "30days" }
]

export default function DashBoard() {
  const searchParams = new URLSearchParams(window.location.search)
  const location = useLocation()
  let navigate = useNavigate()
  const [dashboardList, setDashboardList] = useState()
  const [loading, setloading] = useState(true)
  const [chartVal, setchartVal] = useState([])
  const [upbarChartData, setupbarChartData] = useState({})

  const [params, setParams] = useState({
    sort_key: "",
    sort_value: "",
    search: "",
    page: 1,

  })
  //Set/Delete param
  const setparam = (key, val) => {
    searchParams.set(key, val)
    const to = { pathname: location.pathname, search: searchParams.toString() };
    navigate(to, { replace: true });
  }
  const deleteparam = (key) => {
    searchParams.delete(key)
    const to = { pathname: location.pathname, search: searchParams.toString() };
    navigate(to, { replace: true });
  }


  const DashboardData = (param) => {
    Api.GetApi(`${endPoints.DASHBOARD}${param}`).then(res => {
      if (res.error === true) {
        Api.Alert(res.response.data.error, "error")
        setloading(false)
      } else {
        setDashboardList(res.data.data)
        let arr = []
        let label = []
        arr.push(res.data.data.chargerOnline, res.data.data.chargerOfline)
        label.push(`Online (${res.data.data.chargerOnline})`, `Offline (${res.data.data.chargerOfline})`)
        setchartVal({
          data: arr,
          label: label
        })
        let barDate = []
        let barlabel = []
        res.data.data.revenue.map((data) => {
          barDate.push(moment(data.date).format("MMM DD"))
          barlabel.push(data.revenue)
        })
        setupbarChartData({
          data: barlabel,
          label: barDate
        })
        setloading(false)
      }
    })
  }

  const handleFilter = (e) => {
    setparam("filter", e.target.value)
  }

  useEffect(() => {
    DashboardData(location.search)
  }, [location.search])
  // console.log("upbarChartData", upbarChartData)
  return (
    <>

      {loading ?
        <div className="loader-line"></div> :
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Dashboard</h1>
          </div>
          <section className='section dashboard pt-md-4'>
            <div className="row g-2 g-md-4 ">
              <div className="col-lg-3 col-md-6 mt-0 col-6 mb-2 mb-md-4">
                <div className="card h-100 m-0 info-card sales-card">
                  <div className="card-body dashCard">
                    <h5 className="card-title  pb-md-2">Charging Stations </h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <Icon.EvStation className="me-2" />
                      </div>
                      <div className="ps-2">
                        <h6>{dashboardList?.totalChargers}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mt-0 col-6 mb-2 mb-md-4">
                <div className="card h-100 m-0 info-card sales-card">
                  <div className="card-body dashCard">
                    <h5 className="card-title  pb-md-2">Total Energy Sold</h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <Icon.Lightning className="" />
                      </div>
                      <div className="card-content ps-1">
                        <h6>{energyfunction(dashboardList?.totalEnergySold)} </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6 mt-0 mb-2 mb-md-4">
                <div className="card h-100 m-0 info-card sales-card ">
                  <NavLink to="/charge-session" className="card-body dashCard">
                    <h5 className="card-title  pb-md-2">Total Sessions </h5>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <Icon.ClockHistory className="me-2" />
                      </div>
                      <div className="ps-2">
                        <h6>{dashboardList?.totalSessions}</h6>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6 mt-0 mb-2 mb-md-4">
                <div className="card h-100 info-card sales-card m-0">
                  <div className="card-body dashCard">
                    <h5 className="card-title pb-md-2">
                      Revenue
                      <small>
                        {dashboardList?.revenueGenerate?.hike > 0 ?
                          <span style={{ color: "green" }}><Icon.ChevronDoubleUp className="ms-1" /> {dashboardList?.revenueGenerate?.hike}% </span> :
                          dashboardList?.revenueGenerate?.hike < 0 ? <span style={{ color: "red" }} >  <Icon.ChevronDoubleDown className="ms-1" /> {dashboardList?.revenueGenerate?.hike.toString().split("-")}%
                          </span> : null
                        }


                      </small>

                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <Icon.ClipboardData className="me-2" />
                      </div>
                      <div className="ps-2">
                        <h6>  <CurrencyFormat value={dashboardList?.revenueGenerate?.today.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} /></h6>
                        <small className='d-block m-0'>Yesterday  <CurrencyFormat value={dashboardList?.revenueGenerate?.yesterday.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} /></small>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="card info-card sales-card mb-3">
                  <div className="card-body dashCard">
                    <div className="d-flex align-items-center justify-content-between mb-1">

                      <h5 className="card-title pb-2 m-md-0">Revenue History</h5>
                      <div className="d-flex align-items-center justify-content-end">
                        <select className="ms-2 form-select dashFilterSelect form-md" aria-label="Default select example " value={searchParams.get("filter") || ""} onChange={handleFilter}>
                          {selessionOption.map((data, indx) => {
                            return <option value={data.value} key={indx}>{data.name}</option>
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-3">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-between">
                        <Icon.Cash className="me-2" />
                      </div>
                      <div className="ps-2">
                        <h6>
                          {/* {currencyName} {dashboardList?.revenueAmmount.toFixed(2)} */}
                          <CurrencyFormat value={dashboardList?.totalRevenue.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />
                        </h6>
                      </div>
                    </div>
                    <div className="dashBarChartHolder" style={{
                      // height: "250px",
                      // width: "100%"
                    }}>
                      <Bar options={barChartOptions} data={barChartData(upbarChartData)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="card pieChartcard info-card sales-card">
                  <div className="card-body dashCard">
                    <h5 className="card-title pb-0">Online/Offline Charge Stations </h5>

                    <div className="dashPieChartHolder" style={{ minHeight: "300px", }}>
                      <Pie options={options} data={chartData(chartVal)} />
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </section>

        </main >
      }

    </>
  )
}
