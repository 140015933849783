import { createSlice } from '@reduxjs/toolkit'

export const CommonReducer = createSlice({
    name: 'data',
    initialState: {},
    reducers: {
        dataValue: (state, action) => {
            return { ...state, ...action.payload }
        }
    },
})
export const { dataValue } = CommonReducer.actions
export default CommonReducer.reducer
