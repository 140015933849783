import React, { useEffect, useState } from 'react'
import Index from '../../../Assets/Images/Index'
import { Link, useNavigate } from 'react-router-dom'
import * as Icon from 'react-bootstrap-icons';
import * as Api from "../../../Config/Apis"
import endPoints from "../../../Config/endPoints";
import { isLogIn } from '../../../Config/Apis';
import { useDispatch, useSelector } from 'react-redux';
import { dataValue } from "../../../CommonReducer";


export default function Header() {
    const [isOpen, setIsOpen] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [dropDownTog, setDropDownTog] = useState(false)
    const [profile, setProfile] = useState({})
    const logout = () => {
        localStorage.removeItem("CMP_TOKEN")
        dispatch(dataValue({ 'AUTH_DATA': "" }))
        navigate("/login")
    }
    const dataVal = useSelector((state) => state.dataValue)
    useEffect(() => { }, [dataVal])

    const getProfileDetail = () => {
        Api.GetApi(endPoints.USER_DETAILS, "data").then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                let AUTH_DATA = {}
                AUTH_DATA["email"] = res.data?.data[0]?.email
                AUTH_DATA["name"] = res.data?.data[0]?.name
                AUTH_DATA["profileImg"] = res.data?.data[0]?.profile
                // AUTH_DATA["lname"] = res.data.data?.last_name
                // + resData?.last_name

                dispatch(dataValue({ 'AUTH_DATA': AUTH_DATA }))
                // console.log("res.data.data",res.data.data)
                setProfile(res.data.data)

            }
        })
    }



    useEffect(() => {
        document.body.classList.toggle('toggle-sidebar', isOpen);
    }, [isOpen])

    useEffect(() => {
        document.body.classList.remove('toggle-sidebar');
    }, [window.location.pathname])

    useEffect(() => {
        getProfileDetail()
    }, [])

    const toggleDropDown = () => {
        setDropDownTog(!dropDownTog)
    }
    // console.log("dataVal-header", dataVal)
    return (
        <header id="header" className="header fixed-top d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-between">
                <Link to="/" className="logo d-flex align-items-center">
                    <img src={Index.logo} alt="" />
                </Link>
                <button className='btnTransparent' onClick={() => setIsOpen(!isOpen)}><Icon.Justify className="me-2 toggle-sidebar-btn" /></button>
            </div>
            <div className="header-nav ms-auto">
                <ul className='d-flex align-items-center'>
                    <li>
                        <div onClick={toggleDropDown} className='nav-link nav-profile d-flex align-items-center pe-0'>
                           <span className="navProfile__img me-2">
                            <img src={dataVal?.AUTH_DATA?.profileImg ? dataVal?.AUTH_DATA?.profileImg : Index.profileImg} alt="Profile" className="" />
                            </span> 
                            <span className='dropdown-toggle '>                            
                            {dataVal?.AUTH_DATA?.name}</span>
                        </div>
                        <ul className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow profile ${dropDownTog ? "show" : ""}`} >
                            <li className="dropdown-header">
                          
                                <h6>{dataVal?.AUTH_DATA?.email}{/* &nbsp;{dataVal?.AUTH_DATA?.lname} */}</h6>
                            </li>
                            <li><hr className="dropdown-divider" /></li>
                            <li>
                                <Link onClick={toggleDropDown} className="dropdown-item d-flex align-items-center" to="/profile">
                                    <Icon.Person className="me-2" />
                                    <span>My Profile</span>
                                </Link>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li>
                                <button onClick={logout} className="dropdown-item d-flex align-items-center" >
                                  
                                    <Icon.BoxArrowRight className="me-2" />
                                    <span>Sign Out</span>
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </header>
    )
}
