import React, { useEffect, useState } from 'react'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import moment from 'moment/moment';
import Index from '../../../../Assets/Images/Index';
import Pagination from 'react-bootstrap/Pagination';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import _ from "underscore";
import { addYears } from "date-fns";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CurrencyFormat from 'react-currency-format';
import { energyfunction } from '../../../../Config/energyFunction';
import { formatDate } from '../../../../Config/formatDate'

const options = [
    { name: "Current month", value: "current_month", },
    { name: "Last month", value: "last_month", },
    { name: "Last 12 months", value: "last_12_months", }
]
const ReportingOptions = [
    { name: "Session", value: "session", },
    { name: "Charging Station", value: "charging_station", },

]

export default function Sessions(props) {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [loading, setloading] = useState(true)
    // const [sessionList, setsessionList] = useState([])
    const [selectedReport, setselectedReport] = useState("session")
    const [chargingStationList, setchargingStationList] = useState([])
    const [checkedCharger, setcheckedCharger] = useState([])
    const [showChargStstList, setshowChargStstList] = useState(false)
    const [toutalcount, settoutalcount] = useState({
        totalSessionsRevenue: 0.00,
        totalConsumption: 1,
        TotalUsers: 1,
        TotalchargingStation: 1,
        duration: 1
    })
    const toggleChargerList = () => {
        setshowChargStstList(!showChargStstList)
    }
    const [total, setTotal] = useState(0)
    let limit = 20
    const [filterDate, setfilterDate] = useState({
        from: searchParams.get("from") ? new Date(searchParams.get("from")) : "",
        to: searchParams.get("to") ? new Date(searchParams.get("to")) : ""
    })

    const [params, setParams] = useState({
        filter: searchParams.get("filter") || "",
        search: searchParams.get("search") || "",
        page: searchParams.get("page") || 1,
    })
    const [count, setCount] = useState(1)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    // useEffect(() => {
    //     SessionData(`?search=${params.search}&page=${params.page}&sort_key=${params.sort_key}&sort_value=${params.sort_value}`)
    // }, [params])
    // console.log("sessionList", sessionList)
    // console.log("filterDate", filterDate)
    // console.log("chargingStationList", chargingStationList)
    // console.log("checkedCharger", checkedCharger)
    // console.log("selectedReport", selectedReport)
    console.log("props", props)

    return (
        <>
            {props.list && props.list?.length > 0 ?
                <>
                    <div className="table-responsive">
                        <table className="table dataTable">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th className='sorting' scope="col">User</th>
                                    <th scope="col">Session Id</th>
                                    <th scope="col">Charge Station</th>
                                    <th scope="col">Date/Time</th>
                                    <th scope="col">Duration</th>
                                    <th scope="col">Consumption<small>(kWh)</small></th>
                                    <th scope="col">Cost<small>(MYR)</small></th>
                                    <th scope="col">Payment</th>
                                    <th scope="col">Payment Method</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.list?.map((data, indx) => {
                                    let updInd = indx + 1
                                    return (
                                        <tr key={indx}>
                                            <th scope="row">
                                                {/* {params.page > 1 && params.page} */}
                                                {updInd}
                                            </th>
                                            <td>{data?.first_name || "---"}&nbsp;{data?.last_name || "---"}</td>
                                            <td>{data?._id}</td>
                                            <td>{data?.chargestation_name}</td>
                                            <td>{formatDate(data?.duration?.startTime)}</td>
                                            <td>{data?.duration2} </td>                                          
                                            <td>{energyfunction(data?.consumption)}</td>
                                            {/* <td>{data?.consumption >= 1000 ? data?.consumption / 1000 + "kWh" : data?.consumption >= 1000000 ? data?.consumption / 1000000 + "MWh" : data?.consumption >= 1000000000 ? data?.consumption / 1000000 + "GWh" : data?.consumption >= 1000000000000 ? data?.consumption / 1000000000000 + "TWh" : data?.consumption + "Wh"}</td> */}

                                            <td>
                                                {/* RM {data?.cost ? data?.cost.toFixed(2) : "0.00"} */}
                                                <CurrencyFormat
                                                    value={data?.cost.toFixed(2)}
                                                    displayType={'text'} thousandSeparator={true}
                                                    prefix={'RM '} />

                                            </td>
                                            <td><span className={`badge ${data?.payment_status == "Paid" ? "bg-success" : "bg-danger"}`}>{data?.payment_status}</span></td>
                                            <td>{data?.payment_method || "--"}</td>
                                            <td>{data?.status}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='totalCountBox d-flex justify-content-end'>
                        <ul className="list-group list-group-flush col-4">
                            <li className="list-group-item d-flex justify-content-between"><strong>Total User</strong><span>{props.summary.TotalUsers}</span></li>
                            <li className="list-group-item d-flex justify-content-between"><strong>Total Charge Station</strong><span>{props.summary.TotalchargingStation}</span></li>
                            <li className="list-group-item d-flex justify-content-between"><strong>Total Duration</strong><span>{props.summary.duration}</span></li>
                            <li className="list-group-item d-flex justify-content-between"><strong>Total Consumption</strong><span>{energyfunction(props.summary.totalConsumption)}</span></li>
                            <li className="list-group-item d-flex justify-content-between"><strong>Total Revenue</strong><strong>
                                {/* RM {props.summary.totalSessionsRevenue.toFixed(2)} */}
                                <CurrencyFormat value={props.summary?.totalSessionsRevenue && props.summary?.totalSessionsRevenue.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />
                            </strong></li>
                        </ul>
                    </div>
                    {/* <div className='d-flex align-items-center'>
                                    Showing {sessionList.length} of {total}
                                    <Pagination className='ms-4 mb-0'>
                                        <Pagination.First onClick={prevPage} disabled={params.page == 1 ? true : false} />
                                        {count.length > 0 && count.map((number) => {
                                            return (

                                                <Pagination.Item onClick={() => pageChange(number)} key={number} active={number == params.page}>
                                                    {number}
                                                </Pagination.Item>

                                            )
                                        })}
                                        <Pagination.Last onClick={nextPage} disabled={params.page == count.length ? true : false} />
                                    </Pagination>
                                </div> */}
                </>
                :
                <div className='text-center py-3'>
                    <h3>No result found</h3>
                </div>
            }
        </>
    )
}
