import React, { useEffect, useState } from 'react'
import * as Icon from 'react-bootstrap-icons';
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import Index from '../../../../Assets/Images/Index';
import StateName from '../../../../Config/StateName'

import CurrencyFormat from 'react-currency-format';
import { dataValue } from '../../../../CommonReducer';

import { useDispatch } from 'react-redux';

import Form from 'react-bootstrap/Form';
import _ from 'underscore';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


export default function Profile() {
    const [editProfileTab, seteditProfileTab] = useState(false)
    const [editPasswordTab, seteditPasswordTab] = useState(false)
    const [errorObj, setErrorObj] = useState({
        error: false,
        errorMessage: "",
    })
    const [loading, setloading] = useState(true)
    const dispatch = useDispatch()


    const togglePasswordState = () => {
        setErrorObj({ error: false, errorMessage: "", });
        seteditPasswordTab(!editPasswordTab)
    }
    const [file, setFile] = useState(null)
    const [preview, setpreview] = useState(null)
    const [imgConfirm, setimgConfirm] = useState(false)
    const [editPhone_number, seteditPhone_number] = useState("")
    const [userProfil, setUserProfil] = useState({
        name: "",
        phone_number: "",
        country_code: "",
        email: "",
        old_password: "",
        new_password: "",
        match_password: "",
        partner_profile: null,
        address: {
            country: "Malaysia",
            city: "",
            postalCode: "",
            state: "",

        }

    })

    const [password, setpassword] = useState({
        old_password: false,
        new_password: false,
        match_password: false,
    })
    const toggleShowPassword = (name) => {
        setpassword({ ...password, [name]: !password[name] })
    }
    const areyousureMsg = () => {
        setimgConfirm(!imgConfirm)
    }


    const getProfileDetail = () => {
        Api.GetApi(endPoints.USER_DETAILS,).then(res => {
            // console.log("res", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                Api.Alert(res.response.data.error.message, "error")
                setloading(false)
            } else {
                let AUTH_DATA = {}
                AUTH_DATA["email"] = res.data?.data[0]?.email
                AUTH_DATA["name"] = res.data?.data[0]?.name
                AUTH_DATA["profileImg"] = res.data?.data[0]?.profile
                // AUTH_DATA["lname"] = res.data.data?.last_name
                // + resData?.last_name
                // console.log("AUTH_DATA--", AUTH_DATA)
                dispatch(dataValue({ 'AUTH_DATA': AUTH_DATA }))


                seteditPhone_number(`+${res.data?.data[0]?.country_code} ${res.data?.data[0]?.phone_number}`)

                setUserProfil(res.data.data[0])
                setFile(null)
                setpreview(null)
                setloading(false)
                setimgConfirm(false)

            }
        })
    }

    const updateProfileDate = (data) => {
        Api.PutApi(endPoints.USER_UPDATE, data).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {

                Api.Alert("Updated sucessfully", "success")
                seteditProfileTab(false)
                seteditPasswordTab(false)
                getProfileDetail()

            }
        })
    }

    const DeleteUserImg = () => {
        Api.DeleteApi(endPoints.DELETE_IMAGE).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {

                Api.Alert("Profile picture deleted", "success")
                // seteditProfileTab(false)
                // seteditPasswordTab(false)
                getProfileDetail()

            }
        })
    }



    const handlePhonechange = (value, data) => {
        seteditPhone_number(`+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}`)

        setUserProfil({
            ...userProfil,
            //  'phone_number': `+${data.dialCode} ${value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}`,
            'phone_number': parseInt(value.replace(/[^0-9]+/g, "").slice(data.dialCode.length)) || null,
            'country_code': parseInt(data.dialCode)
        })
    }
    const handleInputchange = (e, type) => {
        if (type === "address") {

            setUserProfil({
                ...userProfil, [type]: {
                    ...userProfil[type],
                    [e.target.name]: e.target.value
                }
            })

        } else {

            setUserProfil({ ...userProfil, [e.target.name]: e.target.value })
        }

    }

    const handleSelect = (e) => {
        setUserProfil({
            ...userProfil,
            "address": {
                ...userProfil["address"],
                [e.target.name]: e.target.value
            }
        })
    }
    const handleFileChange = (event) => {
        let reader = new FileReader();
        let profile = event.target.files[0];

        reader.onloadend = () => {


            if (profile.size <= 5000000) {
                setpreview(reader.result)
                setFile(profile)

            } else {
                Api.Alert("Max file size 5MB", "error")
            }

            // let data = new FormData();
            // data.append("file", profile)

            // Api.PutApi("9000", endPoints.PROFILE_PIC, data).then(res => {

            //     if (res.error === true) {
            //         Api.Alert(res.response.data.error, "error")
            //     } else {
            //         if (res.data.data) {
            //             let resData = res.data
            //             Api.Alert(resData.message, "success")
            //             getEmployeeDetail()
            //         }
            //     }
            // })

        };
        if (!_.isUndefined(profile)) {
            reader.readAsDataURL(profile);
        }

    }
    const [errors, setErrors] = useState(false)
    const [invalidPhone, setInvalidPhone] = useState(false)

    const handleProfileSubmit = (event) => {
        event.preventDefault();
        // console.log("userProfil", userProfil)

        if (!userProfil?.phone_number || userProfil?.phone_number === "" || userProfil?.phone_number.toString().length < 9 || document.querySelectorAll(".form-control.invalid-number").length > 0) {
            setErrors(true)
            setInvalidPhone(true)
        }
        else {
            setErrors(false)
            setInvalidPhone(false)
            let updateaddress = {
                country: "Malaysia",
                city: userProfil?.address?.city,
                postalCode: userProfil?.address?.postalCode,
                state: userProfil?.address?.state,
            }
            // console.log("updateaddress", updateaddress)

            let data = new FormData();
            data.append('email', userProfil.email);
            // data.append('old_password', userProfil.old_password);
            // data.append('new_password', userProfil.new_password);
            data.append('name', userProfil?.name);
            // data.append('email', userProfil?.email);
            data.append('phone_number', userProfil?.phone_number);
            data.append('country_code', userProfil?.country_code);
            data.append('address', JSON.stringify(updateaddress));
            if (file !== null) {
                data.append('partner_profile', file);
            }
            seteditPhone_number("")
            updateProfileDate(data)
        }
    }



    const handlePasswordSubmit = (event) => {
        event.preventDefault();
        var reg = new RegExp(
            "^(?=.*)(?=.*[a-z])(?=.*[!@#%&])(?=.*[0-9])(?=.*[A-Z]).{8,32}$"
        );
        var validPass = reg.test(userProfil.new_password);
        let formData = new FormData();
        if (userProfil.old_password == "") {
            setErrorObj({
                error: true,
                errorMessage: "Please enter Old Password",
            });
        } else if (!validPass) {
            setErrorObj({
                error: true,
                errorMessage: "Create a secure password with at least 8 characters, including uppercase, lowercase, numbers, and symbols",
            });
        } else if (userProfil.new_password == "") {
            setErrorObj({
                error: true,
                errorMessage: "Please enter New Password",
            });
        } else if (userProfil.match_password == "") {
            setErrorObj({
                error: true,
                errorMessage: "Please enter Confirm Password",
            });
        } else if (userProfil.new_password !== userProfil.match_password) {
            setErrorObj({
                error: true,
                errorMessage: "New password and confirm password should be same",
            });
        }

        else {

            setErrorObj({ error: false, errorMessage: "", });
            // formData.append('email', userProfil.email);
            // formData.append('old_password', userProfil.old_password);
            // formData.append('new_password', userProfil.new_password);
            // formData.append('name', userProfil?.name);
            // // formData.append('email', userProfil?.email);
            // formData.append('phone_number', userProfil?.phone_number);
            // formData.append('partner_profile', userProfil?.img);

            let obj = {
                email: userProfil.email,
                old_password: userProfil.old_password,
                new_password: userProfil.new_password,
                name: userProfil.name,
                phone_number: userProfil.phone_number,
            }

            updateProfileDate(obj)
        }

    }
    const showEditState = () => {
        seteditProfileTab(true)
    }
    const hideEditState = () => {
        seteditProfileTab(false)
        getProfileDetail()
    }

    useEffect(() => {
        getProfileDetail()
    }, [])

    useEffect(() => {
    }, [userProfil])

    // console.log("userProfil-- ", userProfil)
    // console.log("errors-- ", errors)
    // console.log("invalidPhone-- ", invalidPhone)

    // ADMIN
    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Profile</h1>
            </div>

            <div className="row profile">
                <div className="col-xl-6">
                    <div className="card profile-card">
                        <div className="card-body  ">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className=" "><h5 className="card-title m-0"> {editProfileTab ? "Update Profile" : "Profile"}</h5></div>
                                <div className=" ">
                                    {editProfileTab ?
                                        <button className="btn btn-outline-danger" onClick={hideEditState}><Icon.XLg /></button> :
                                        <button className="btn btn-primary " onClick={showEditState}><Icon.Pencil /> </button>
                                    }

                                </div>
                            </div>
                            <div className='mb-4'>
                                <div className='userProfileUpdate'>
                                    {preview ?
                                        <img src={preview} alt="Profile" className=" " /> :
                                        <img src={userProfil.profile ? userProfil.profile : Index.profileImg} alt="Profile" className=" " />
                                    }
                                </div>
                                {editProfileTab ?
                                    <div className="pt-2">

                                        {imgConfirm ?
                                            <>
                                                <button className="btn btn-outline-danger btn-sm ms-2" onClick={DeleteUserImg} > Confirm</button>
                                                <button className="btn btn-danger btn-sm ms-2" onClick={() => setimgConfirm(false)} >Dismiss</button>
                                            </>
                                            : <>
                                                <label className="btn btn-primary btn-sm" title="Upload new profile image"><Icon.Upload />
                                                    <input type='file' className="d-none" accept="image/png, image/gif, image/jpeg" onChange={(e) => handleFileChange(e)} />
                                                </label>
                                                {userProfil.profile ?
                                                    <button className="btn btn-danger btn-sm ms-2" onClick={areyousureMsg} title="Remove my profile image"><Icon.Trash /></button> : null
                                                }


                                            </>
                                        }

                                    </div>

                                    : null



                                }
                            </div>
                            <form className="row g-3" onSubmit={handleProfileSubmit}>
                                <div className="col-md-6 col-12">
                                    <label htmlFor="" className="form-label">Name*</label>
                                    <input type="text" name='name' className="form-control" onChange={(e) => handleInputchange(e, "")} value={userProfil?.name} readOnly={editProfileTab ? false : true} id="" required />
                                </div>
                                <div className="col-md-6 col-12">
                                    <label htmlFor="" className="form-label">Phone number*</label>
                                    {/* <input type="text"id="" /> */}
                                    {/* <CurrencyFormat name='phone_number' onChange={(e) => handleInputchange(e, "")} value={userProfil?.phone_number} readOnly={editProfileTab ? false : true} className="form-control" format="##########" /> */}

                                    {/* <PhoneInput
                                        country={'60'}
                                        value={userProfil?.phone_number}
                                        // readOnly={editProfileTab ? false : true} 
                                        onChange={(e) => handleInputchange(e, "")}
                                    /> */}
                                    {editProfileTab ?

                                        <>
                                            <PhoneInput
                                                className={invalidPhone || !userProfil?.phone_number || userProfil?.phone_number == "" || userProfil?.phone_number.toString().length < 9 || userProfil?.phone_number.toString().length > 12 ? "is-invalid" : ""}
                                                enableSearch={true}
                                                disableAreaCodes={false}
                                                disableSearchIcon={true}
                                                disabled={editProfileTab ? false : true}
                                                country={"my"}
                                                value={editPhone_number.toString()}
                                                inputExtrastate={{ name: 'mobile', required: true }}
                                                inputProps={{ maxLength: '17' }}
                                                onChange={(e, x) => handlePhonechange(e, x)}
                                                isValid={(value, data) => {
                                                    if (
                                                        value
                                                            .toString().replace(/[^0-9]+/g, "")
                                                            .slice(data && (data.dialCode.length)).length < 9 &&
                                                        value
                                                            .toString().replace(/[^0-9]+/g, "")
                                                            .slice(data && (data.dialCode.length)).length > 0
                                                    ) {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                                placeholder=""
                                                countryCodeEditable={false}
                                                defaultMask=".. ... ...."
                                            />
                                            {errors && (!userProfil?.phone_number || userProfil?.phone_number == "") ?
                                                <div className='invalid-feedback'>Please enter phone number</div>
                                                : null}

                                            {errors && invalidPhone && userProfil?.phone_number && userProfil?.phone_number != "" ?
                                                <div className='invalid-feedback'>Enter valid phone number</div>
                                                : null
                                            }

                                        </> :
                                        <input type="text" name='name' className="form-control"
                                            onChange={(e) => handleInputchange(e, "")} value={editPhone_number} readOnly disabled id="" required />
                                    }

                                </div>
                                <div className="col-md-6 col-12">
                                    <label htmlFor="" className="form-label">Email*</label>
                                    <input type="email" name='email' className="form-control" onChange={(e) => handleInputchange(e, "")} value={userProfil?.email} readOnly={editProfileTab ? false : true} id="" required />
                                </div>
                                <div className="col-md-6 col-12">
                                    <label htmlFor="" className="form-label">State*</label>

                                    {/* <input type="text" name='state' className="form-control" onChange={(e) => handleInputchange(e, "address")} value={userProfil?.address?.state} readOnly={editProfileTab ? false : true} id="" required /> */}
                                    {editProfileTab ? <Form.Select onChange={handleSelect} name='state' value={userProfil.address.state}>
                                        <option value={""} defaultValue disabled>Select state</option>
                                        {Object.keys(StateName).map((key) => {
                                            return (
                                                <option value={key} key={key}>{key}</option>
                                            )
                                        })}
                                    </Form.Select> :
                                        <input type="text" name='state' className="form-control" onChange={(e) => handleInputchange(e, "address")} value={userProfil?.address?.state} readOnly id="" required />}
                                </div>
                                <div className="col-md-6 col-12">
                                    <label htmlFor="" className="form-label">City*</label>
                                    {/* <input type="text" name='city' className="form-control" onChange={(e) => handleInputchange(e, "address")} value={userProfil?.address?.city} readOnly={editProfileTab ? false : true} id="" required /> */}
                                    {editProfileTab ?
                                        <Form.Select onChange={handleSelect} name='city' value={userProfil.address.city} disabled={userProfil?.address?.state == ""}>
                                            <option value={""} defaultValue disabled>Select city</option>
                                            {!_.isEmpty(userProfil) && userProfil?.address?.state !== "" && _.contains(Object.keys(StateName), userProfil?.address?.state) && StateName[userProfil?.address?.state].map((key) => {
                                                return (
                                                    <option value={key} key={key}>{key}</option>
                                                )
                                            })}
                                        </Form.Select>
                                        : <input type="text" name='city' className="form-control" onChange={(e) => handleInputchange(e, "address")} value={userProfil?.address?.city} readOnly id="" required />
                                    }

                                </div>
                                <div className="col-md-6 col-12">
                                    <label htmlFor="" className="form-label">Postal Code*</label>
                                    <input type="text" name='postalCode' className="form-control" onChange={(e) => handleInputchange(e, "address")} value={userProfil?.address?.postalCode} readOnly={editProfileTab ? false : true} id="" required />
                                </div>

                                {/* <div className="col-12">
                                    <label htmlFor="" className="form-label">Password</label>
                                    <input type="password" name='password' className="form-control" readOnly={editProfileTab ? false : true} id="" />
                                </div> */}
                                {editProfileTab ?
                                    <div className="text-center1">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                        <button className="btn btn-secondary ms-2" onClick={hideEditState}>Cancel</button>
                                    </div> : null}
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="card profile-card">
                        <div className="card-body  ">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className=" "><h5 className="card-title m-0">Change Password</h5></div>
                                <div className=" "> <button className={`btn ${editPasswordTab ? "btn-outline-danger" : "btn-primary"}`} onClick={togglePasswordState}>{editPasswordTab ? <Icon.XLg /> : <Icon.Pencil />}</button></div>
                            </div>
                            {errorObj.error ? <div className="invalid-feedback mb-2 d-block">{errorObj.errorMessage}</div> : null}
                            {editPasswordTab ?
                                <form className="row g-3" onSubmit={handlePasswordSubmit}>
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Current Password*</label>
                                        <div className='passwordField'>
                                            <input
                                                type={password.old_password ? "text" : "password"}
                                                name='old_password' className="form-control" value={userProfil?.old_password} onChange={handleInputchange} required />
                                            <button type="button" className='passwordField__btn' onClick={() => toggleShowPassword("old_password")}>
                                                {password.old_password ? <Icon.EyeSlashFill /> : <Icon.EyeFill />}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">New Password*</label>
                                        <div className='passwordField'>
                                            <input type={password.new_password ? "text" : "password"}
                                                name='new_password' className="form-control" value={userProfil?.new_password} onChange={handleInputchange} id="" required />
                                            <button type="button" className='passwordField__btn' onClick={() => toggleShowPassword("new_password")}>
                                                {password.new_password ? <Icon.EyeSlashFill /> : <Icon.EyeFill />}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Confirm Password*</label>
                                        <div className='passwordField'>
                                            <input type={password.match_password ? "text" : "password"}
                                                name='match_password'
                                                className="form-control"
                                                value={userProfil?.match_password} onChange={handleInputchange} id="" required />
                                            <button type="button" className='passwordField__btn' onClick={() => toggleShowPassword("match_password")}>
                                                {password.match_password ? <Icon.EyeSlashFill /> : <Icon.EyeFill />}
                                            </button>
                                        </div>
                                    </div>


                                    <div className="text-center1">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                        <button type="button" className="btn btn-secondary ms-2" onClick={togglePasswordState}>Cancel</button>
                                    </div>
                                </form> : null}
                        </div>
                    </div>
                </div>
            </div>

        </main >
    )
}
