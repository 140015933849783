export default {
    LOGIN: "user/login",
    DASHBOARD: "dashboard",
    CHARGE_SESSIONS: "charge/sessions",
    USER_DETAILS: "user/details",
    USER_UPDATE: "user/update",
    USER_VERIFY: "user/verify",
    SESSION: "report/sessions",
    CHARGE_STATIONS: "report/charge-staion",
    FORGOT_PASSWORD: "user/forgot/password",
    DELETE_IMAGE: "user" 


}
