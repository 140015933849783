import React, { useState } from 'react'
import Index from '../../../Assets/Images/Index'
import { Link } from 'react-router-dom'

import * as Api from "../../../Config/Apis"
import endPoints from "../../../Config/endPoints";




export default function FotgotPassword() {

    const [value, setValue] = useState({
        email: "",
    })

    const handlechange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let data = { ...value }
        forgotPasswordfunct(data.email)
    }


    const forgotPasswordfunct = (param) => {
        Api.GetApi(`${endPoints.FORGOT_PASSWORD}?email=${param}`).then(res => {
            if (res.error === true) {
                Api.Alert(res?.res?.data?.error, "error")
            }
            else {
                if (res.data?.testkeys) {
                    if (Api.env == "dev") {
                        Api.Alert("Mail sent successfully", "success")
                        setTimeout(() => {
                            window.open(res.data?.testkeys, "_blank")
                        }, 2000);
                    }
                    else {
                        Api.Alert(res.data?.testkeys, "success")
                    }
                }
            }
        })
    }


    return (
        <div className="loginPage">
            <div className="container">
                <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="loginPageContainer justify-content-center">
                        <div className="d-flex justify-content-center py-4">
                            <div className="logo d-flex align-items-center w-auto">
                                <img src={Index.logo} alt="" />
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="pb-2">
                                    <h5 className="card-title text-center fs-4">Forgot Password</h5>
                                </div>
                                <form className="row g-3 needs-validation"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="col-12">
                                        <label htmlFor="yourUsername" className="form-label">Enter your Email address</label>
                                        <div className="input-group has-validation">
                                            <input type="text"
                                                value={value.email}
                                                name="email"
                                                onChange={handlechange}
                                                className="form-control" id="yourUsername" required="" />
                                            <div className="invalid-feedback">Please enter your username.</div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button
                                            className={`btn btn-primary w-100 ${value.email === "" ? "disabled" : null}`}
                                            onClick={handleSubmit}
                                            type="submit">Reset Password </button>
                                    </div>
                                    <div className="col-12">
                                        <p className="mb-0">Already have an account? <Link to="/login">login</Link></p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
