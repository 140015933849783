import React, { useEffect, useState } from 'react'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import moment from 'moment/moment';
import Index from '../../../../Assets/Images/Index';
import Pagination from 'react-bootstrap/Pagination';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import _ from "underscore";
import { energyfunction } from '../../../../Config/energyFunction';
import CurrencyFormat from 'react-currency-format';
import ResponsivePagination from 'react-responsive-pagination';
import { formatDate } from '../../../../Config/formatDate';
const options = [
    { name: "Name", value: "first_name", },
    { name: "Charger", value: "chargestation_name", }
]

const options2 = [
    { name: "Paid", value: "Paid", },
    { name: "Unpaid", value: "Unpaid", },
    { name: "Cancelled", value: "Cancelled", },
    { name: "Refunded", value: "Refunded", }
]
const options3 = [
    { name: "Card", value: "Card", },
    { name: "Wallet", value: "Wallet", },
]
const sortTypes = [
    { name: "Ascending ", value: "asc", },
    { name: "Descending  ", value: "desc", },
]
export default function ChargeSession() {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [loading, setloading] = useState(true)
    const [showFilterBtn, setshowFilterBtn] = useState(false);
    const [search, setsearch] = useState(searchParams.get("search") || "")
    const [total, setTotal] = useState(0)
    const [sessionList, setsessionList] = useState()
    let limit = 20
    const [params, setParams] = useState({
        sort_key: searchParams.get("sort_key") || "",
        sort_value: searchParams.get("sort_value") || "asc",
        search: searchParams.get("search") || "",
        page: searchParams.get("page") || 1,
        payment: searchParams.get("payment") || "",
        payment_method: searchParams.get("payment_method") || "",
    })
    const [count, setCount] = useState(1)


    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }


    const getChargeSessions = (param) => {
        Api.GetApi(`${endPoints.CHARGE_SESSIONS}${param}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                let arr = []
                res.data.data.length > 0 && res.data.data[0].result.length > 0 && res.data.data[0].result.map((opt) => {
                    // var a = moment(opt?._id?.duration?.startTime);
                    // var b = moment(opt?._id?.duration?.endTime);
                    // var duration = moment.duration(b.diff(a));
                    // duration.hours()
                    // duration.minutes()
                    // duration.seconds()

                    // let duration1 = `${duration.hours() > 0 ? `${duration.hours()}hr` : ""} 
                    // ${duration.minutes() > 0 ? `${duration.minutes()}min ` : ""}       ${duration.seconds() > 0 ? `${duration.seconds()}s` : ""}`

                      var milliseconds = moment(opt?._id?.duration?.endTime, 'YYYY-MM-DD hh:mm:ss A').diff(moment(opt?._id?.duration?.startTime, 'YYYY-MM-DD hh:mm:ss A'));


                    // console.log("milliseconds", milliseconds)
                    let seconds = parseInt(Math.round(((milliseconds / 1000) % 60)));
                    let minutes = parseInt((((milliseconds / (1000 * 60)) % 60)));
                    let hours = parseInt((((milliseconds / (1000 * 60 * 60)) % 24)));
                    // console.log("hours", hours , "minutes",  minutes,   "seconds", seconds )
                    // console.log("duration1", duration1 )

                    let duration3 = ` ${hours > 0 ? `${hours}h` : ""} ${minutes > 0 ? `${minutes}m` : ""} ${seconds > 0 ? `${seconds}s` : ""}`

                    arr.push({ ...opt?._id, durationMain: duration3 })
                })

                setsessionList(arr)
                if (res.data.data.length > 0) {
                    setTotal(res.data.data[0]?.totalCount?.count)
                    let newCount = Math.ceil(parseInt(res.data.data[0]?.totalCount?.count) / limit)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount(items)
                    } else {
                        setCount(1)
                    }
                }
                setloading(false)
            }
        })
    }

    const updateBlockStat = (e) => {
        // UPDATEBLOCKSTAT
        Api.PutApi(endPoints.UPDATEBLOCKSTAT, { "_id": e.target.value }).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                getChargeSessions("")
                Api.Alert("Updated sucessfully", "success")
            }
        })
    }
    const searchUser = (e) => {
        deleteparam("page")
        setsearch(e.target.value)
        setParams({ ...params, [e.target.name]: e.target.value, page: 1 })
        if (e.target.value !== "") {
            setparam([e.target.name], e.target.value)
        } else {
            deleteparam("search")
        }
    }
    const onSelectChange = (e) => {
        setshowFilterBtn(true)
        setParams({ ...params, [e.target.name]: e.target.value })
        if (e.target.name == "sort_value") {
            if (params.sort_key != "") {
                setparam("sort_value", e.target.value)
            } else {
                deleteparam("sort_value")
            }
        } else {
            setparam([e.target.name], e.target.value)
            if (e.target.name !== "payment" && e.target.name !== "payment_method") {
                setparam("sort_value", params.sort_value)
            }
        }
        // getChargeSessions(`?sort_key=${params.sort_key}&sort_value=${params.sort_value}&page=${params.page}`)
    }


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (params.search != "") {
                setparam('search', params.search)
            }
            // getChargeSessions(`?search=${params.search}`)
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [params.search])

    const maxVisiblePages = 5;
    const getPageItems = () => {
        const pageItems = [];
        const totalPages = count.length;

        for (let number = 1; number <= totalPages; number++) {
            if (
                number === 1 ||
                number === totalPages ||
                (number >= params.page - Math.floor(maxVisiblePages / 2) &&
                    number <= params.page + Math.floor(maxVisiblePages / 2))
            ) {
                pageItems.push(
                    <Pagination.Item
                        key={number}
                        active={number == params.page}
                        onClick={() => pageChange(number)}
                    >
                        {number}
                    </Pagination.Item>
                );
            } else if (
                (number === 2 && params.page > Math.floor(maxVisiblePages / 2) + 1) ||
                (number === totalPages - 1 &&
                    params.page < totalPages - Math.floor(maxVisiblePages / 2) - 1)
            ) {
                // Display ellipsis for the second and second-to-last pages
                pageItems.push(<Pagination.Ellipsis key={number} />);
            }
        }

        return pageItems;
    };


    useEffect(() => {
        setParams({ ...params, page: searchParams.get("page") || 1 })
    }, [searchParams.get("page")])


    const setCurrentPage = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            setloading(true)
        }
    }

    const prevPage = () => {
        setloading(true)
        setparam("page", Number(params.page) - 1)
        setParams({ ...params, page: params.page - 1 })
    }
    const nextPage = () => {
        setloading(true)
        setparam("page", Number(params.page) + 1)
        setParams({ ...params, page: params.page + 1 })
    }
    const pageChange = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            setloading(true)
        }
    }



    const clearFilter = () => {
        setshowFilterBtn(false)
        setParams({
            ...params,
            sort_key: "",
            sort_value: "asc",
            payment: "",
            payment_method: "",
        })
        deleteparam("sort_key")
        deleteparam("sort_value")
        deleteparam("payment")
        deleteparam("payment_method")
    }
    const clearSearch = () => {
        setParams({
            ...params,
            search: "",
        })
        deleteparam("search")
    }
    // console.log("sessionList", sessionList)
    // useEffect(() => {
    //     getChargeSessions(`?search=${params.search}&page=${params.page}&sort_key=${params.sort_key}&sort_value=${params.sort_value}`)
    // }, [params])

    useEffect(() => {
        if (!searchParams.get("search")) {
            clearSearch()
            setsearch("")
            
        }
        if (searchParams.get("payment_method") || searchParams.get("payment") || searchParams.get("sort_key")) {
            setshowFilterBtn(true)
            
        } else {
            setshowFilterBtn(false) 
            clearFilter()
        }
        if (searchParams.get("page")  && searchParams.get("page")  != params.page) {  
            setParams({ ...params, page: searchParams.get("page")  })
            console.log("page param missing")
            console.log("param: ", params.page) 
        }

        getChargeSessions(location.search)
        // getChargeSessions(`?search=${params.search}&page=${params.page}`)
    }, [location.search])
    console.log(sessionList)
    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Charge Sessions </h1>
            </div>
            {loading ?
                <div className="loader-line"></div> :
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex mb-0 mb-md-4 justify-content-between align-items-center userdetailTopRow">


                            <div className="col-md-5 userdetailTopRow__left">

                                <div className="d-flex align-items-start align-items-sm-center  mb-3 mb-md-2 flex-column flex-sm-row" >


                                    <div className="search-bar pe-1">
                                        <div className="search-form d-flex align-items-center" >
                                            <input type="text" className='form-control' name="search" placeholder="Search by user/session id/charge station" value={search} title="Enter search keyword" onChange={searchUser} />
                                            <span className="searchBarIcon" title="Search"><Icon.Search /></span>
                                        </div>
                                    </div>
                                    {searchParams.get("search") && searchParams.get("search") !== "" ?
                                        <button type="button" className="textClear nowrap" onClick={clearSearch}>Clear Search</button> : null
                                    }
                                </div>
                            </div>

                            <div className=" d-flex align-items-center userdetailTopRow__right" >
                                <div className='sortingSelect_outer me-3 '>
                                    <select className='sortingSelect' name='payment' value={params.payment} onChange={onSelectChange}>
                                        <option disabled defaultValue value="">Payment Status </option>
                                        {options2.map((data, index) => {
                                            return (
                                                <option key={index} value={data.value}>{data.name}</option>
                                            )
                                        })

                                        }
                                    </select>
                                </div>
                                <div className='sortingSelect_outer me-3 '>
                                    <select className='sortingSelect' name='payment_method' value={params.payment_method} onChange={onSelectChange}>
                                        <option disabled defaultValue value="">Filter By </option>
                                        {options3.map((data, index) => {
                                            return (
                                                <option key={index} value={data.value}>{data.name}</option>
                                            )
                                        })

                                        }
                                    </select>
                                </div>
                                <div className='sortingSelect_outer'>
                                    <select className='sortingSelect' name='sort_key' value={params.sort_key} onChange={onSelectChange}>
                                        <option disabled defaultValue value="">Sort By</option>
                                        {options.map((data, index) => {
                                            return (
                                                <option key={index} value={data.value}>{data.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                {searchParams.get("sort_key") && searchParams.get("sort_key") !== "" ?
                                    <div className='sortingSelect_outer ms-2'>
                                        <select className='sortingSelect' name='sort_value' value={params.sort_value} onChange={onSelectChange}>

                                            {sortTypes.map((data, index) => {
                                                return (
                                                    <option key={index} value={data.value}>{data.name}</option>
                                                )
                                            })}
                                        </select> </div> : null}
                                {showFilterBtn ?
                                    <button type="button" className="textClear" onClick={clearFilter}>Clear Filter</button> : null}
                            </div>
                        </div>
                        {sessionList && sessionList.length > 0 ?
                            <>
                                <div className="table-responsive">
                                    <table className="table dataTable">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th className='sorting' scope="col">User</th>
                                                <th scope="col">Session Id</th>
                                                <th scope="col">Charge Station</th>
                                                <th scope="col">Date/Time</th>
                                                <th scope="col">Duration</th>
                                                <th scope="col">Consumption<small>(kWh)</small></th>
                                                <th scope="col">Cost<small>(MYR)</small></th>
                                                <th scope="col">Payment</th>
                                                <th scope="col">Payment Method</th>
                                                <th scope="col">Status</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sessionList.map((data, indx) => {
                                                let updInd = indx + 1
                                                return (
                                                    <tr key={indx}>
                                                        <th scope="row">{(params.page - 1) * 20 + updInd}</th>
                                                        <td>{data?.first_name}&nbsp;{data?.last_name}</td>
                                                        <td>{data?._id}</td>
                                                        <td>{data?.chargestation_name}</td>
                                                        <td>{formatDate(data?.duration?.startTime)}</td>
                                                        <td>{data?.durationMain || "--"}</td>
                                                        <td>{energyfunction(data?.consumption)}</td>
                                                        <td>
                                                            {/* {Api.currencyName} {data?.cost ? data?.cost.toFixed(2) : "0.00"} */}
                                                            <CurrencyFormat value={data?.cost.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />

                                                        </td>
                                                        <td><span className={`badge ${data?.payment_status == "Paid" ? "bg-success" : "bg-danger"}`}>
                                                            {data?.payment_status}</span></td>
                                                        <td>{data?.payment_method || "--"}</td>
                                                        <td>{data?.status}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>

                                </div>
                                <div className='d-flex align-items-center'>
                                    {/* <div className='me-4'> Showing {sessionList.length} of {total}</div> */}

                                    <div style={{ maxWidth: "500px" }}>
                                        <ResponsivePagination
                                            current={parseFloat(searchParams.get("page"))}
                                            total={count.length}
                                            onPageChange={setCurrentPage}
                                            disabled={params.page === count.length}
                                            // maxWidth={500}
                                            className={"pagination "}
                                        />

                                    </div>


                                </div>
                            </>
                            :
                            <div className='text-center py-3'>
                                <h3>No result found</h3>
                            </div>
                        }
                    </div>
                </div>
            }
        </main >
    )
}
