import React, { useEffect, useState } from 'react'
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import moment from 'moment/moment';
import Index from '../../../../Assets/Images/Index';
import Pagination from 'react-bootstrap/Pagination';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import _ from "underscore";
import { addYears } from "date-fns";
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import CurrencyFormat from 'react-currency-format';
const options = [
    { name: "Current month", value: "current_month", },
    { name: "Last month", value: "last_month", },
    { name: "Last 12 months", value: "last_12_months", }
]

export default function ChargeStation(props) {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()
    const [loading, setloading] = useState(true)
    const [sessionList, setsessionList] = useState()
    const [chargingStationList, setchargingStationList] = useState()
    const [total, setTotal] = useState(0)
    const [toutalcount, settoutalcount] = useState({
        totalRevenue: 0.00,
        TotalchargingStation: 1,
    })
    let limit = 20


    const [filterDate, setfilterDate] = useState({
        from: searchParams.get("from") ? new Date(searchParams.get("from")) : "",
        to: searchParams.get("to") ? new Date(searchParams.get("to")) : ""
    })

    const [params, setParams] = useState({
        filter: searchParams.get("filter") || "",
        search: searchParams.get("search") || "",
        page: searchParams.get("page") || 1,
        from: searchParams.get("from"),
        to: searchParams.get("to"),
    })
    const [count, setCount] = useState(1)
    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const chargeStationget = (param) => {
        Api.GetApi(`${endPoints.CHARGE_STATIONS}${param}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                setchargingStationList(res.data.data?.chargeStation[0])
                setloading(false)
                // console.log("res.data.data", res.data.data)
                settoutalcount({
                    totalRevenue: res.data.data?.totalRevenue,
                    TotalchargingStation: res.data.data?.TotalchargingStation,
                })
                if (res.data.data?.chargeStation.length > 0) {
                    setTotal(res.data.data?.chargeStation[0]?.totalCount?.count)
                    let newCount = Math.ceil(parseInt(res.data.data?.chargeStation[0]?.totalCount?.count) / limit)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount(items)
                    } else {
                        setCount(1)
                    }
                }

            }
        })
    }
    const onDateChange = (date, name) => {
        if (name === "from") {
            setfilterDate({ ...filterDate, [name]: date, to: "" })
        } else {
            setfilterDate({ ...filterDate, [name]: date })
        }
    }

    const searchUser = (e) => {
        deleteparam("page")
        setParams({ ...params, [e.target.name]: e.target.value, page: 1 })
        if (e.target.value !== "") {
            setparam([e.target.name], e.target.value)
        } else {
            deleteparam("search")
        }
    }
    const onSelectChange = (e) => {
        setParams({ ...params, [e.target.name]: e.target.value })
        setparam([e.target.name], e.target.value)
    }
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (params.search != "") {
                setparam('search', params.search)
            }
            // SessionData(`?search=${params.search}`)
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [params.search])

    const prevPage = () => {
        setloading(true)
        setParams({ ...params, page: params.page - 1 })
    }
    const nextPage = () => {
        setloading(true)
        setParams({ ...params, page: params.page + 1 })
    }
    const pageChange = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            setloading(true)
        }
    }
    const clearFilter = () => {
        setParams({
            ...params,
            filter: "",
        })
        deleteparam("filter")
    }
    const [dateError, setdateError] = useState(false)
    const filterDateChange = () => {
        if (filterDate?.from == "" || filterDate?.to == "") {
            setdateError(true)
        } else {
            setdateError(false)
            setParams({ ...params, "from": moment(filterDate?.from).format("YYYY-MM-DD"), "to": moment(filterDate?.to).format("YYYY-MM-DD") })
            setparam("from", moment(filterDate?.from).format("YYYY-MM-DD"))
            setparam("to", moment(filterDate?.to).format("YYYY-MM-DD"))
        }
    }
    const clearDate = () => {
        setfilterDate({ from: "", to: "" });
        setParams({ ...params, from: "", to: "" });
        deleteparam("from")
        deleteparam("to")
    };

    const handleExport = () => {
        // console.log("dsfdf")
        axios({
            method: 'get',
            url: `${Api.appBaseURL}${endPoints.CHARGE_STATIONS}/csv${location.search}`,
            responseType: 'blob',
            headers: {
                "token": Api.isLogIn().token,
            }
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]), {
                type: 'text/csv'
            });

            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = `charge_station${filterDate?.from && filterDate?.to ? `_${moment(filterDate?.from).format("YYYY-MM-DD")}_to_${moment(filterDate?.to).format("YYYY-MM-DD")}` : `_${moment(new Date()).format("YYYY-MM-DD")}`}.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }).catch((err) => {
            Api.Alert(err, "error")
        });
    }


    useEffect(() => {
        // chargeStationget(location.search)
    }, [location.search])

    useEffect(() => {
        // chargeStationget(`?page=${params.page}&filter=${params.filter}`)
    }, [params])
    // console.log("chargingStationList", chargingStationList)
    // console.log("filterDate", filterDate)
    // console.log("props.list", props.list)

    return (
        <>
            {props.list.length > 0 ?
                <>
                    <div className="table-responsive">
                        <table className="table dataTable">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th className='sorting' scope="col">Charge Station Id</th>
                                    <th className='sorting' scope="col">Charge Station Name</th>
                                    <th scope="col">Revenue</th>

                                </tr>
                            </thead>
                            <tbody>
                                {props.list?.map((data, indx) => {
                                    let updInd = indx + 1
                                    return (
                                        <tr key={indx}>
                                            <th scope="row">
                                                {/* {params.page > 1 && params.page} */}
                                                {updInd}
                                            </th>
                                            <td>{data?._id._id}</td>
                                            <td>{data?._id.chargestation_name}</td>
                                            <td>
                                                {/* RM {data?._id.revenue.toFixed(2)} */}
                                                <CurrencyFormat
                                                    value={data?._id.revenue ? data?._id.revenue.toFixed(2) : 0}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'RM '} />

                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='totalCountBox d-flex justify-content-end'>
                        <ul className="list-group list-group-flush col-4">
                            <li className="list-group-item d-flex justify-content-between"><strong>Total Charging Station</strong><span>{props.summary.TotalchargingStation}</span></li>
                            <li className="list-group-item d-flex justify-content-between"><strong>Total Revenue</strong>
                                <strong>
                                    <CurrencyFormat value={props.summary?.totalRevenue && props.summary?.totalRevenue.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />
                                </strong></li>                                    </ul>
                    </div>
                    {/* <div className='d-flex align-items-center'>
                                    Showing {chargingStationList?.result.length} of {total}
                                    <Pagination className='ms-4 mb-0'>
                                        <Pagination.First onClick={prevPage} disabled={params.page == 1 ? true : false} />
                                        {count.length > 0 && count.map((number) => {
                                            return (

                                                <Pagination.Item onClick={() => pageChange(number)} key={number} active={number == params.page}>
                                                    {number}
                                                </Pagination.Item>

                                            )
                                        })}
                                        <Pagination.Last onClick={nextPage} disabled={params.page == count.length ? true : false} />
                                    </Pagination>
                                </div> */}
                </>
                :
                <div className='text-center py-3 text-center'>
                    <h3>No result found</h3>
                </div>
            }
        </>
    )
}
