export const energyfunction = (unit) => {
    return (
        unit > 0 &&  unit < 1000000 ? (unit / 1000).toFixed(3) + "kWh" :
        unit >= 1000000 && unit < 1000000000 ?  (unit / 1000000).toFixed(3) + "MWh" :
        unit >= 1000000000 && unit < 1000000000000 ? (unit / 1000000).toFixed(3) + "GWh" :
        unit >= 1000000000000 ? (unit / 1000000000000).toFixed(3) + "TWh" :
        "0kWh"
    )

}
